$slider-bg: #1d9ca3;

// classes with double dashes belong to react-pageflip internals

.clc-pdf-player {
  background-color: #d9d7d7;
  position: relative;
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;

  .book {
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 2px 5px);
    transform: translate3d(0,0,0);
    transition: transform ease-in-out .4s;
  }

  .first-page {
    .book {
      transform: translate3d(-25%,0,0);

      .page-shadow {
        opacity: 0;
      }
    }

    .nav-control-prev {
      opacity: 0;
      cursor: default;
    }
  }
  .last-page {
    .book {
      transform: translate3d(25%,0,0);

      .page-shadow {
        opacity: 0;
      }
    }
    .nav-control-next {
      opacity: 0;
      cursor: default;
    }
  }

  .loading-placeholder {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    width: 560px;
    height: 790px;
    border: #dbdbdb 1px solid;
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 2px 5px);
    background-color: #e7e7e7;
    padding: 16px;
  }

  .navigation-slider {
    position: absolute;
    top: -12px;
  }

  input[type=range] {
    appearance: none;
    height: 24px;
    width: 100%;
    background-color: transparent;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background: $slider-bg;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 2px #312A2A;
    border: 0px solid #00001E;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: $slider-bg;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background: $slider-bg;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 2px #312A2A;
    border: 0px solid #00001E;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: #FFFFFF;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: $slider-bg;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: $slider-bg;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 2px #312A2A;
    border: 0px solid #00001E;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: #FFFFFF;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: $slider-bg;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: $slider-bg;
  }

  .pdf-viewer-hud {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 64px;
    background-color: rgba(0, 0, 0, 0.8);
    transition: opacity .3s ease-in-out;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    color: $white;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    .page-counter {
      font-weight: 700;
      cursor: default;
    }

    .pdf-viewer-controls {
      width: 100%;
      padding: 16px 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      user-select: none;
    }
  }

  .clc-pdf-player-button {
    background-color: transparent;
    border: 0px solid transparent;
    outline: none;
    padding: 4px;
    color: $white;
    border-radius: 4px;
    transition: background-color .2s linear;
    cursor: pointer;

    &:active,
    &:focus {
      outline: none;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.25);
    }
  }

  .nav-control {
    position: fixed;
    top: 50%;
    z-index: 5;
    transition: opacity .2s linear;
    opacity: 1;
    transform: translateY(-50%);

    svg {
      filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, .4));
    }

    &-next {
      right: 16px;
    }
    &-prev {
      left: 16px;
    }
  }

  // TODO: only for zoom
  .clc-pdf-player-canvas {
    // transition: transform .3s ease-in-out;
    display: flex;
    justify-content: center;
    transition: opacity .2s linear;
    opacity: 1;

    &.on-zoom {
      .book {
        cursor: grab;
      }
    }
  }
}

.page-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  z-index: 1;
  pointer-events: none;
  opacity: 1;
  transition: opacity .2s linear;

  .--right & {
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.3) 0px, rgba(0, 0, 0, 0.15) 3%, rgba(0, 0, 0, 0.075) 6%, rgba(255, 255, 255, 0.05) 16%, rgba(255, 255, 255, 0) 100%);
  }
  .--left & {
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.25) 0px, rgba(0, 0, 0, 0.12) 2%, rgba(0, 0, 0, 0.075) 4%, rgba(255, 255, 255, 0.05) 9%, rgba(255, 255, 255, 0) 100%);
  }
}
