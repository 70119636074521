.quicksearch {
  position: relative;

  // customers have a different-colored navbar so the quicksearch needs to accommodate
  &.quicksearch-as-customer {
    .input-group-text {
      background: #{$dropdown-bg};
      border-color: rgb(112 44 5);
      color: #{$dropdown-color};
      border-right: none;
    }

    &.has-query {
      .input-group {
        margin-top: -1px;
        margin-bottom: -1px;
      }
    }

    &.has-query input[name="query"], input[name="query"] {
      border: 1px solid rgb(112 44 5);
      &:focus {
        box-shadow: none;
        border: 1px solid rgb(112 44 5);
      }
    }

    button.btn-danger {
      border: 1px solid #911621;
      border-left: none;
    }

    .results {
      border: 1px solid rgb(112 44 5);
      .results-inner {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .input-group-text {
    background: #{$dropdown-dark-bg};
    border-color: #545f69;
    color: white;
  }

  input[name="query"] {
    border: 1px solid #fff;
    &:focus {
      box-shadow: none;
      border: 1px solid #fff;
    }
  }

  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0;
  }

  &.show-results {
    .input-group-text {
      border-bottom-left-radius: 2px;
    }
    .input-group button {
      border-bottom-right-radius: 2px;
    }
    .input-group input:last-child {
      border-bottom-right-radius: 2px;
    }
  }

  &.has-query {
    input[name="query"] {
      border: 1px solid #fff;
      &:focus {
        border: 1px solid #fff;
      }
    }
  }

  .results {
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    border: 1px solid var(--bs-border-color-translucent);
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    .results-inner {
      background-color: #{$dropdown-dark-bg};
      border-left: 1px solid #{$dropdown-dark-border-color};
      border-right: 1px solid #{$dropdown-dark-border-color};
      border-bottom: 1px solid #{$dropdown-dark-border-color};
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    li {
      font-size: 85%;
      display: flex;
      justify-content: space-between;
      a.result-link {
        flex: 1;
        border: 1px solid rgb(202 202 202 / 18%);
        color: #{$dropdown-dark-link-color};
        &:hover, &.selected {
          color: #{$dropdown-dark-link-hover-color};
          background: #{$dropdown-dark-link-hover-bg};
          border-color: rgba(255, 255, 255, 0.15);
        }
        &:active {
          color: #{$dropdown-dark-link-active-color};
          background: #{$dropdown-dark-link-active-bg};
        }
      }
    }
  }

}
