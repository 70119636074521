.clc-layout-content-section {
  @extend .p-4;
  @extend .gap-4;

  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 62px);
  overflow: auto;
}

.vertical-flip {
  transform: rotate(180deg);
}

.fill-base {
  fill: var(--bs-body-color);
}

.clc-modal--lg {
  --bs-modal-width: 800px;
}
.clc-modal--xl {
  --bs-modal-width: 1000px;
}
.clc-modal--xxl {
  --bs-modal-width: 1200px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.button-with-notify {
  &::after {
    content: '!';
    position: absolute;
    top: -5px;
    right: -5px;
    width: 10px;
    height: 10px;
    background-color: var(--bs-danger);
    border-radius: 50%;
    color: white;
    font-size: 7px;
    font-weight: bolder;
  }
}

.card-header-rounded {
  border-radius: var(--bs-card-inner-border-radius)!important;
}

.dropdown-scrollable-menu {
  max-height: 250px;
  overflow-y: auto;
}

.form-label {
  font-weight: 500;
}

.btn-reset {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  // cursor: pointer;
  text-align: inherit;
  // appearance: none; /* remove native styles */
  // -webkit-appearance: none; /* remove native styles for Chrome, Safari */
  // -moz-appearance: none; /* remove native styles for Firefox */
  outline: none;
  box-shadow: none;
  transition: none;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

table.no-border-bottom tbody tr:last-child {
  td, th {
    border-bottom: none;
  }
}
