@use 'sass:color';

$color-series-a: $turkos;
$color-series-b: #5cb85c;
$color-series-c: #ea5a0b;
$color-negative-series-a: color.adjust($color-series-a, $lightness: -10%, $saturation: -5%);
$color-negative-series-b: color.adjust($color-series-b, $lightness: -10%, $saturation: -5%);
$color-negative-series-c: color.adjust($color-series-c, $lightness: -10%, $saturation: -5%);


.chart-legend-sample {
  display: inline-block;
  height: 16px;
  width: 16px;
  outline: 1px solid $white;
  border-radius: 2px;

  &.series-a {
    background-color: $color-series-a;
  }
  &.series-b {
    background-color: $color-series-b;
  }
  &.series-c {
    background-color: $color-series-c;
  }
}

.chart-legend-item {
  &:hover .chart-legend-sample-clear {
    background-color: transparent;
    outline: none;

    &::before {
      opacity: 1;
      scale: 1;
    }
  }

  .chart-legend-sample {
    &-clear {
      position: relative;
      transition: background-color .1s ease-in-out, outline .1s ease-in-out;

      &::before {
        content: '×';
        display: block;
        position: absolute;
        top: -8px;
        left: 2.5px;
        font-size: 20px;
        opacity: 0;
        transition: opacity .1s ease-in-out, scale .1s ease-in-out;
        scale: 0.5;
      }
    }
  }

  &-has-value {
    .dropdown-toggle  {
      --bs-btn-color: #{$dark};
    }
  }
}

.dropdown-item {
  .chart-legend-sample {
    display: inline-block;
    height: 16px;
    width: 16px;
    outline: 1px solid $white;
    border-radius: 2px;

    &.series-a {
      background-color: $color-series-a;
    }
    &.series-b {
      background-color: $color-series-b;
    }
    &.series-c {
      background-color: $color-series-c;
    }
  }
}

.ct-chart {
  &-bar {
    .ct-label {
      color: $secondary;
    }
    .ct-bar {
      stroke-width: 20px;
    }

    &-sm {
      .ct-bar {
        stroke-width: 10px;
      }
    }
    .ct-series-a .ct-bar {
      stroke: $color-series-a;

      &.negative-value {
        stroke: $color-negative-series-a;
      }
    }
    .ct-series-b .ct-bar {
      stroke: $color-series-b;

      &.negative-value {
        stroke: $color-negative-series-b;
      }
    }
    .ct-series-c .ct-bar {
      stroke: $color-series-c;

      &.negative-value {
        stroke: $color-negative-series-c;
      }
    }
    .ct-grids {
      .ct-grid.ct-vertical {
        stroke: #{$border-color};
        &.base-grid {
          stroke-width: 2px;
          stroke: color.adjust($border-color, $lightness: -20%);
        }
      }
    }

    .ct-label-rotate .ct-label {
      transform: rotate(-28deg);
      text-wrap: nowrap;
      transform-origin: 28px 43px;
    }

    &-sm {
      .ct-label-rotate .ct-label {
        transform: rotate(-45deg);
        text-wrap: nowrap;
        transform-origin: 22px 32px;
      }
    }
  }
}
